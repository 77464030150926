<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('integrationsPage.title')"
      :subTitle="$t('integrationsPage.firstText')"
    />

    <section class="d-flex flex-wrap mx-2">
      <NbCardLinkBrand
        class="m-2"
        :isConnected="false"
        modalTarget="#modalMabang"
        :image="require('@/assets/img/integrations/mabang-logo.png')"
        label="MABANG ERP"
      >
        <div
          class="modal fade nb-modal"
          id="modalMabang"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalMabang"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ $t("integrationsPage.connectMabang") }}
                </h5>
                <button
                  type="button"
                  @click="initializeMabang"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="text-align: center">
                <div
                  class="mt-3"
                  style="
                    padding-bottom: 5px;
                    padding-right: 50px;
                    padding-left: 50px;
                  "
                >
                  <NbButton class="col-12" @click="addIntegration">
                    {{ $t("integrationsPage.getToken") }}
                  </NbButton>
                </div>
                <div
                  class=""
                  v-if="token != null"
                  style="
                    padding-bottom: 5px;
                    padding-right: 50px;
                    padding-left: 50px;
                  "
                >
                  <NbButton
                    size="sm"
                    class="col-12 mt-2"
                    @click="copyToClipboard"
                  >
                    {{ token }}
                    <input type="hidden" :value="token" id="token" />
                  </NbButton>
                  <h6 style="width: 100%; text-align: center">
                    ({{ $t("integrationsPage.getTokenCaption") }})
                  </h6>
                </div>
                <hr />
                <div class="body-3">
                  {{ $t("integrationsPage.getTokenText") }}
                  <div>
                    <a
                      class="link-2 float-left"
                      style="padding: 5px 15px 40px"
                      href="https://www.nobordist.com/integrations-mabang"
                      target="_blank"
                      >{{ $t("integrationsPage.getTokenMoreInfo") }} ></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NbCardLinkBrand>

      <NbCardLinkBrand
        class="m-2"
        :isConnected="false"
        modalTarget="#modalshipstation"
        :image="require('@/assets/img/integrations/shipstation-logo.png')"
        label="Shipstation"
      >
        <modal-form
          modalId="modalshipstation"
          :modalTitle="$t('integrationsPage.shipstationTitle')"
          :modalMessage="$t('integrationsPage.shipstationText')"
          :okButton="$t('integrationsPage.shipstationOkButton')"
          :cancelButton="$t('cancel')"
          :width="518"
          :formAttributes="genericKeys"
          @emitForm="sendForm('shipstation', $event)"
        />
      </NbCardLinkBrand>

      <NbCardLinkBrand
        class="m-2"
        :isConnected="false"
        modalTarget="#modalshopify"
        :image="require('@/assets/img/integrations/shopify-logo.png')"
        label="Shopify"
      >
        <modal-form
          modalId="modalshopify"
          :modalTitle="$t('integrationsPage.shopifyTitle')"
          :modalMessage="$t('integrationsPage.shopifyText')"
          :okButton="$t('integrationsPage.shopifyOkButton')"
          :cancelButton="$t('cancel')"
          :width="518"
          :formAttributes="genericKeys"
          @emitForm="sendForm('shopify', $event)"
        />
      </NbCardLinkBrand>

      <NbCardLinkBrand
        class="m-2"
        :isConnected="false"
        modalTarget="#modalvtex"
        :image="require('@/assets/img/integrations/vtex-logo.png')"
        label="Vtex"
      >
        <modal-form
          modalId="modalvtex"
          :modalTitle="$t('integrationsPage.vtexTitle')"
          :modalMessage="$t('integrationsPage.vtexText')"
          :okButton="$t('integrationsPage.vtexOkButton')"
          :cancelButton="$t('cancel')"
          :width="518"
          :formAttributes="genericKeys"
          @emitForm="sendForm('vtex', $event)"
        />
      </NbCardLinkBrand>

      <NbCardLinkBrand
        class="m-2"
        :isConnected="false"
        modalTarget="#modalAmazon"
        :image="require('@/assets/img/integrations/amazon-logo.png')"
        label="Amazon"
      >
        <div
          class="modal fade nb-modal"
          id="modalAmazon"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalAmazon"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Connect to Amazon</h5>
                <button
                  type="button"
                  id="amazonClose"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="text-align: center">
                <div
                  class="mt-3"
                  style="
                    padding-bottom: 5px;
                    padding-right: 50px;
                    padding-left: 50px;
                  "
                >
                  <router-link to="/amazon-import-files" data-dismiss="modal">
                    <NbButton class="col-12"> My Previous Imports </NbButton>
                  </router-link>
                </div>
                <div
                  class="mt-2"
                  style="
                    padding-bottom: 5px;
                    padding-right: 50px;
                    padding-left: 50px;
                  "
                ></div>
                <div
                  style="
                    padding-bottom: 5px;
                    padding-right: 50px;
                    padding-left: 50px;
                  "
                >
                  <NbButton class="col-12">
                    <label>
                      {{ this.uploading }}
                      <input
                        type="file"
                        class="d-none"
                        v-on:change="processFile"
                        accept=".csv"
                      />
                    </label>
                  </NbButton>
                </div>
                <hr />
                <div class="body-3" style="padding: 5px 15px 40px">
                  <a
                    class="link-2"
                    href="/files/amazon-import.csv"
                    download="amazon-import.csv"
                    >Download here</a
                  >
                  the template CSV sheet
                </div>
              </div>
            </div>
          </div>
        </div>
      </NbCardLinkBrand>
    </section>
  </div>
</template>
<script>
import NProgress from "nprogress";
import IntegrationsService from "../../services/IntegrationService.js";
import OrderService from "../../services/OrderService.js";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import ModalForm from "@/components/integrations/ModalForm";
import NbCardLinkBrand from "@/components/cards/NbCardLinkBrand.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";

const integrationsService = new IntegrationsService();
const orderService = new OrderService();

export default {
  name: "IntegrationsList",
  components: {
    ModalForm,
    LoadingPage,
    NbCardLinkBrand,
    NbPageTitle,
    NbButton,
  },
  data: () => {
    return {
      file: null,
      loaded: true,
      integrationName: null,
      token: null,
      uploading: "Import New File",
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.loaded = false;
    }, 600);
  },
  methods: {
    closeAmazon() {
      document.getElementById("amazonClose").click();
    },
    processFile(event) {
      this.file = event.target.files[0];
      this.uploading = "Importing...";
      const postParameters = new FormData();
      postParameters.append("file", this.file, this.file.name);
      orderService
        .amazonImport(postParameters)
        .then((response) => {
          this.$router.push({
            path: "/orders/amazon-import",
            query: {
              orders: response.data.data.orders,
              errors: response.data.data.errors,
              contracts: response.data.data.contracts,
              amazon_file_id: response.data.data.amazon_file_id,
            },
          });
          this.closeAmazon();
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
      this.uploading = "Import New File";
    },
    sendForm(slug, event) {
      let dataToSend = JSON.parse(JSON.stringify(event));
      dataToSend.slug = slug;
      integrationsService.setDeliveryMethod(dataToSend).then(() => {
        document.getElementById(`"modal${slug}-close"`).click();
      });
    },
    addIntegration() {
      let body = { integration_name: this.integrationName };
      integrationsService
        .addIntegration(body)
        .then((response) => {
          this.errorsIntegration = [];
          this.token = response.data.data.token;
          this.succesMessage(response.data.messages);
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorMessage(response.data.messages[0]);
          }
          NProgress.done();
        });
    },
    succesMessage(msg) {
      this.$helpers.toast(msg, "success");
    },
    errorMessage(msg) {
      this.$helpers.toast(msg, "danger");
    },
    initializeMabang() {
      this.integrationName = "mabang";
      this.token = null;
    },
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.querySelector("#token");
      copyText.setAttribute("type", "text"); // change type to 'text' otherwise it doesn't work
      copyText.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      copyText.setAttribute("type", "hidden"); // set type back to 'hidden'
      this.$helpers.toast(this.token, "info");
    },
  },
  computed: {
    genericKeys() {
      return [
        { key: "account_nickname", label: this.$t("integrationsPage.accNick") },
        {
          key: "password",
          label: this.$t("integrationsPage.accPass"),
          type: "password",
        },
        {
          key: "account_number",
          label: this.$t("integrationsPage.accNumber"),
          type: "number",
        },
        {
          key: "site_id",
          label: this.$t("integrationsPage.siteId"),
          type: "number",
        },
      ];
    },
  },
};
</script>

<style>
.button-light > .row > img {
  object-fit: contain;
}

.nb-modal .modal-header {
  border-bottom: 0px !important;
  padding: 40px 40px 0px 40px;
}
.nb-modal .modal-body {
  padding: 12px 40px 0px 40px;
}
.nb-modal .modal-footer {
  border-top: 0px !important;
  padding: 12px 40px 0px 40px;
}
</style>
