<template>
  <!-- modals -->
  <div class="modal fade modal-background nb-modal" :id="modalId" role="dialog">
    <div
      class="modal-dialog"
      :class="modalConfig"
      :style="`max-width: ${width}px;`"
    >
      <div class="modal-content" :style="`width: ${width}px;`">
        <div class="modal-header">
          <h5 class="modal-title heading-5" :id="`${modalId}Title`">
            {{ modalTitle }}
          </h5>
          <button
            :id="`${modalId}-close`"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body">
            <div
              v-if="modalMessage"
              :class="modalLink ? 'body-4 mb-2' : 'body-4 mb-4'"
            >
              {{ modalMessage }}
            </div>
            <div v-if="modalLink" class="body-3 mb-4">
              {{ modalLink.message }}
              <div
                class="link-1"
                @click="$emit('linkClicked', modalLink.link.src)"
              >
                {{ modalLink.link.text }}
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
              <div
                v-for="formInput in formInputs"
                :key="formInput.key"
                class="form-group"
              >
                <div
                  v-if="formInput.key && formInput.type == 'boolean'"
                  style="max-width: 13.12rem"
                >
                  <label :for="formInput.key + modalId">{{
                    formInput.label
                  }}</label>
                  <b-form-select
                    v-model="content[formInput.key]"
                    :options="options"
                    class="form-control"
                    :id="formInput.key + modalId"
                  ></b-form-select>
                </div>

                <div
                  v-else-if="formInput.key && formInput.type == 'dates'"
                  style="max-width: 13.12rem"
                  class="d-inline"
                >
                  <div class="d-inline-block mr-3" style="min-width: 13.12rem">
                    <label :for="formInput.key + modalId"
                      >Start {{ formInput.label }}</label
                    >
                    <input
                      :required="formInput.required"
                      v-model="content[`start_${formInput.key}`]"
                      type="date"
                      class="form-control"
                      :id="formInput.key + modalId"
                      :placeholder="`${formInput.label} ..`"
                    />
                  </div>
                  <div class="d-inline-block" style="min-width: 13.12rem">
                    <label :for="formInput.key + modalId + 'end'"
                      >End {{ formInput.label }}</label
                    >
                    <input
                      v-model="content[`end_${formInput.key}`]"
                      type="date"
                      class="form-control"
                      :id="formInput.key + modalId + 'end'"
                      :placeholder="`${formInput.label} ..`"
                    />
                  </div>
                </div>

                <NbSelectInput
                  v-else-if="formInput.type === 'select'"
                  style="min-width: 13.12rem"
                  :required="formInput.required"
                  :rules="formInput.rules || ''"
                  :id="formInput.id"
                  :name="formInput.name"
                  :helpText="formInput.helpText"
                  :error="errors[formInput.key] || formInput.error"
                  :options="formInput.options"
                  @valid="$emit('updateValidFields', $event)"
                  @invalid="$emit('invalid', $event)"
                  v-model="content[formInput.key]"
                />

                <div
                  v-else-if="formInput.key && formInput.type == 'password'"
                  style="min-width: 13.12rem"
                >
                  <NbPassInput
                    :id="formInput.key + modalId"
                    :required="formInput.required"
                    :name="formInput.label"
                    :placeholder="`${formInput.label} ..`"
                    v-model="content[formInput.key]"
                  />
                </div>

                <NbTextInput
                  v-else-if="formInput.type === 'email'"
                  style="min-width: 13.12rem"
                  :required="formInput.required"
                  :rules="formInput.rules || 'email'"
                  :id="formInput.id"
                  :name="formInput.name"
                  :helpText="formInput.helpText"
                  :type="formInput.type"
                  :append="formInput.append"
                  :prepend="formInput.prepend"
                  :error="errors[formInput.key] || formInput.error"
                  :placeholder="formInput.placeholder"
                  @valid="$emit('updateValidFields', $event)"
                  @invalid="$emit('invalid', $event)"
                  v-model="content[formInput.key]"
                />

                <NbTextInput
                  v-else
                  style="min-width: 13.12rem"
                  :id="formInput.id"
                  :required="formInput.required"
                  :rules="formInput.rules || ''"
                  :name="formInput.name"
                  :masks="formInput.masks"
                  :helpText="formInput.helpText"
                  :type="formInput.type"
                  :append="formInput.append"
                  :prepend="formInput.prepend"
                  :decimals="formInput.decimals"
                  :min="formInput.min"
                  :step="formInput.step"
                  :disabled="formInput.disabled"
                  :maxlength="formInput.maxlength"
                  :placeholder="formInput.placeholder"
                  :error="errors[formInput.key] || formInput.error"
                  @valid="$emit('updateValidFields', $event)"
                  @invalid="$emit('invalid', $event)"
                  v-model="content[formInput.key]"
                />
              </div>
            </div>
          </slot>
          <slot />
        </div>
        <div class="modal-footer" style="margin-bottom: 2.5rem">
          <slot name="footer">
            <!-- <NbButton 
              size="sm" 
              variant="secondary"
              @click="clearForm"
              data-dismiss="modal"
            >
              {{cancelButton ? cancelButton : 'Close'}}
            </NbButton> -->
            <NbButton size="sm" :disabled="disabledOkButton" @click="emitForm">
              {{ okButton ? okButton : "Ok" }}
            </NbButton>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";

export default {
  name: "ModalForm",
  components: {
    NbButton,
    NbPassInput,
    NbTextInput,
    NbSelectInput,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    formInputs: {
      type: Array,
      required: false,
    },
    modalId: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalMessage: {
      type: String,
      required: false,
    },
    modalLink: {
      type: Object,
      required: false,
    },
    okButton: {
      type: String,
      required: false,
    },
    disabledOkButton: {
      type: Boolean,
      required: false,
    },
    cancelButton: {
      type: String,
      required: false,
    },
    modalConfig: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    emitForm() {
      this.$emit("emitForm", this.content);
    },
    clearForm() {
      this.content = {};
    },
  },
  computed: {
    options() {
      return [
        { value: null, text: this.$t("orderStatus.selectOption") },
        { value: true, text: this.$t("components.tableFilters.trueText") },
        { value: false, text: this.$t("components.tableFilters.falseText") },
      ];
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) this.content = this.value;
    },
    content: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>

<style scoped>
.nb-modal .modal-header {
  border-bottom: 0px !important;
  padding: 40px 40px 0px 40px;
}
.nb-modal .modal-body {
  padding: 12px 40px 0px 40px;
}
.nb-modal .modal-footer {
  border-top: 0px !important;
  padding: 12px 40px 0px 40px;
}
/* 
.modal-background {
  background: black !important; 
}
  */
</style>
