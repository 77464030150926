import HttpService from "./HttpService";

export default class IntegrationsService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Add Integration
   *
   * @param {*} data
   * @returns {Promise}
   */
  addIntegration(data = {}) {
    return this.httpService.post("/v1/sales_channels_permissions", data);
  }

  setDeliveryMethod(data = {}) {
    return this.httpService.post("/seller_delivery_methods/", data);
  }
}
