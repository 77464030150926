var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('LoadingPage'):_c('div',[_c('NbPageTitle',{attrs:{"title":_vm.$t('integrationsPage.title'),"subTitle":_vm.$t('integrationsPage.firstText')}}),_c('section',{staticClass:"d-flex flex-wrap mx-2"},_vm._l((_vm.possibleConctiones),function(possibleConctione){return _c('NbCardLinkBrand',{key:possibleConctione.slug_name + 'key',staticClass:"m-2",attrs:{"connections":_vm.checkIfIsConnected(possibleConctione.slug_name),"modalTarget":`#${possibleConctione.slug_name}`,"image":possibleConctione.imagem,"label":possibleConctione.label},on:{"disconect":function($event){return _vm.switchModalForm('integrationList', possibleConctione.slug_name)},"click":function($event){_vm.resetNewDeliveryMethod(possibleConctione.keysForm);
        _vm.switchModalForm('', possibleConctione.slug_name);}}},[_c('ModalForm',{attrs:{"modalId":`${possibleConctione.slug_name}`,"modalTitle":_vm.showModalForm === 'integrationList'
            ? `Connections with ${possibleConctione.label}`
            : possibleConctione.modalTitle,"modalMessage":possibleConctione.modalMessage,"modalLink":_vm.modalLink(possibleConctione.slug_name),"okButton":possibleConctione.okButton,"disabledOkButton":_vm.allOk,"cancelButton":_vm.$t('cancel'),"modalConfig":"modal-dialog-centered","width":525,"formInputs":_vm.allkeys[possibleConctione.keysForm],"errors":_vm.errors},on:{"linkClicked":function($event){return _vm.switchModalForm($event, possibleConctione.slug_name)},"updateValidFields":function($event){return _vm.updateValidFields($event)},"invalid":function($event){return _vm.invalid($event)},"emitForm":function($event){return _vm.sendForm(possibleConctione.slug_name)}},scopedSlots:_vm._u([(_vm.showModalForm === 'integrationList')?{key:"body",fn:function(){return _vm._l((_vm.contractsBySlugName),function(contract,index){return _c('div',{key:contract.id,staticClass:"d-flex justify-content-between border-bottom m-auto"},[_c('div',{staticClass:"w-64-5 m-auto"},[_vm._v(" "+_vm._s(contract.name)+" ")]),_c('NbButton',{staticClass:"my-1",attrs:{"variant":"tertiary","icon":"fas fa-edit"},on:{"click":function($event){return _vm.copyContracToNewDeliveryMethod(
                  contract,
                  possibleConctione.keysForm
                )}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('NbButton',{staticClass:"my-1",attrs:{"variant":"tertiary","icon":"fas fa-trash","data-toggle":"modal","data-target":"#modalDeleteCourier"},on:{"click":function($event){return _vm.setContractToDelete(contract.id, index)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)})},proxy:true}:null,(_vm.showModalForm === 'integrationList')?{key:"footer",fn:function(){return [_c('NbButton',{staticClass:"mt-2",attrs:{"size":"sm","variant":"tertiary"},on:{"click":function($event){_vm.showModalForm = ''}}},[_vm._v(" < "+_vm._s(_vm.$t("back"))+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.newDeliveryMethod),callback:function ($$v) {_vm.newDeliveryMethod=$$v},expression:"newDeliveryMethod"}})],1)}),1),_c('NbModal',{attrs:{"id":"modalDeleteCourier","modalConfig":"modal-dialog-centered","preventClose":true,"width":"525px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"heading-4"},[_vm._v(" "+_vm._s(_vm.$t("confirmDelete", { val: "" }))+" ")])]},proxy:true},{key:"body",fn:function(){return undefined},proxy:true},{key:"footer",fn:function(){return [_c('NbButton',{staticClass:"float-right mr-3",on:{"click":function($event){return _vm.setObsolete(_vm.contractToDelete)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c('NbButton',{staticClass:"float-right mr-2",attrs:{"variant":"secondary","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }