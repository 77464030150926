import HttpService from "./HttpService";

export default class PendingActionService {
  constructor() {
    this.httpService = HttpService;
  }

  fetchAdminUsers() {
    return this.httpService.get("/v1/pending-actions/fetch_admins");
  }
}
