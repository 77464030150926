<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('integrationsPage.title')"
      :subTitle="$t('integrationsPage.firstText')"
    />

    <section class="d-flex flex-wrap mx-2">
      <NbCardLinkBrand
        v-for="possibleConctione in possibleConctiones"
        :key="possibleConctione.slug_name + 'key'"
        class="m-2"
        :connections="checkIfIsConnected(possibleConctione.slug_name)"
        :modalTarget="`#${possibleConctione.slug_name}`"
        :image="possibleConctione.imagem"
        :label="possibleConctione.label"
        @disconect="
          switchModalForm('integrationList', possibleConctione.slug_name)
        "
        @click="
          resetNewDeliveryMethod(possibleConctione.keysForm);
          switchModalForm('', possibleConctione.slug_name);
        "
      >
        <ModalForm
          :modalId="`${possibleConctione.slug_name}`"
          :modalTitle="
            showModalForm === 'integrationList'
              ? `Connections with ${possibleConctione.label}`
              : possibleConctione.modalTitle
          "
          :modalMessage="possibleConctione.modalMessage"
          :modalLink="modalLink(possibleConctione.slug_name)"
          :okButton="possibleConctione.okButton"
          :disabledOkButton="allOk"
          :cancelButton="$t('cancel')"
          modalConfig="modal-dialog-centered"
          :width="525"
          :formInputs="allkeys[possibleConctione.keysForm]"
          :errors="errors"
          @linkClicked="switchModalForm($event, possibleConctione.slug_name)"
          @updateValidFields="updateValidFields($event)"
          @invalid="invalid($event)"
          @emitForm="sendForm(possibleConctione.slug_name)"
          v-model="newDeliveryMethod"
        >
          <template v-if="showModalForm === 'integrationList'" #body>
            <div
              v-for="(contract, index) in contractsBySlugName"
              :key="contract.id"
              class="d-flex justify-content-between border-bottom m-auto"
            >
              <div class="w-64-5 m-auto">
                {{ contract.name }}
              </div>
              <NbButton
                class="my-1"
                variant="tertiary"
                icon="fas fa-edit"
                @click="
                  copyContracToNewDeliveryMethod(
                    contract,
                    possibleConctione.keysForm
                  )
                "
              >
                {{ $t("edit") }}
              </NbButton>
              <NbButton
                class="my-1"
                variant="tertiary"
                icon="fas fa-trash"
                data-toggle="modal"
                data-target="#modalDeleteCourier"
                @click="setContractToDelete(contract.id, index)"
              >
                {{ $t("delete") }}
              </NbButton>
            </div>
          </template>
          <template v-if="showModalForm === 'integrationList'" #footer>
            <NbButton
              class="mt-2"
              size="sm"
              variant="tertiary"
              @click="showModalForm = ''"
            >
              &lt; {{ $t("back") }}
            </NbButton>
          </template>
        </ModalForm>
      </NbCardLinkBrand>
    </section>

    <!-- modals -->
    <NbModal
      id="modalDeleteCourier"
      modalConfig="modal-dialog-centered"
      :preventClose="true"
      width="525px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("confirmDelete", { val: "" }) }}
        </div>
      </template>
      <template v-slot:body> </template>
      <template v-slot:footer>
        <NbButton
          class="float-right mr-3"
          @click="setObsolete(contractToDelete)"
        >
          {{ $t("delete") }}
        </NbButton>
        <NbButton
          variant="secondary"
          class="float-right mr-2"
          data-dismiss="modal"
        >
          {{ $t("cancel") }}
        </NbButton>
      </template>
    </NbModal>
    <!-- /modals -->
  </div>
</template>
<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import ModalForm from "@/components/integrations/ModalForm";
import NbModal from "@/components/modal/NbModal.vue";
import NbCardLinkBrand from "@/components/cards/NbCardLinkBrand.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NProgress from "nprogress";

import ContractService from "../../services/ContractService";
import PriceTablesService from "@/services/PriceTablesService";
import DeliveryTimesService from "@/services/DeliveryTimesService";

const contractService = new ContractService();
const priceTablesService = new PriceTablesService();
const deliveryTimesService = new DeliveryTimesService();

export default {
  name: "IntegrationsList",
  components: {
    ModalForm,
    LoadingPage,
    NbCardLinkBrand,
    NbPageTitle,
    NbButton,
    NbModal,
  },
  data: () => {
    return {
      file: null,
      loaded: true,
      integrationName: null,
      token: null,
      uploading: "Import New File",
      priceTablesOptions: [],
      deliveryTimesOptions: [],
      newDeliveryMethod: {
        id: null,
        slug: "",
        price_table_id: null,
        delivery_time_id: null,
        tracking_senha: "",
        tracking_usuario: "",
        dispatch_login_hash: "",
        dispatch_cartao_postagem: "",
        dispatch_authorizatio: "",
        dispatch_contract: "",
        dispatch_key: "",
        dispatch_token: "",
      },
      newDeliveryMethodModel: {
        id: null,
        slug: "",
        price_table_id: null,
        delivery_time_id: null,
        tracking_senha: "",
        tracking_usuario: "",
        dispatch_login_hash: "",
        dispatch_cartao_postagem: "",
        dispatch_authorizatio: "",
        dispatch_contract: "",
        dispatch_key: "",
        dispatch_token: "",
      },
      carriers: [],
      carrierOptions: [],
      cooperations: [],
      cooperationsOptions: [],
      contracts: [],
      contractsBySlugName: [],
      errors: {},
      trackShow: false,
      dispatchShow: false,
      tkey: "",
      tvalue: "",
      dkey: "",
      dvalue: "",
      showModalForm: "",
      currentKeys: [],
      contractToDelete: {},
      allOk: true,
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.loaded = false;
    }, 600);
    this.loadPriceTables();
    this.loadDeliveryTimes();
    this.loadContracts();
  },
  methods: {
    sendForm(slug) {
      if (this.newDeliveryMethod && this.newDeliveryMethod.id) {
        this.editContract(slug);
        return;
      }
      this.createContract(slug);
    },
    createContract(slug) {
      let dataToSend = { ...this.newDeliveryMethod };
      dataToSend.slug_name = slug;
      contractService
        .setSellerContract(dataToSend)
        .then(() => {
          document.getElementById(`${slug}-close`).click();
          this.$helpers.toast("Contract Created", "success", 3000);
          this.resetNewDeliveryMethod();
          this.loadContracts();
        })
        .catch(({ response }) => {
          NProgress.done();
          this.$helpers.toast(
            this.$t("integrationsPage.contractNotCreated"),
            "danger",
            3000
          );
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errors = response.data.messages[0];
          }
        });
    },
    editContract(slug) {
      let dataToSend = { ...this.newDeliveryMethod };
      dataToSend.slug_name = slug;
      contractService
        .updateContractSeller(dataToSend.id, dataToSend)
        .then(() => {
          document.getElementById(`${slug}-close`).click();
          this.$helpers.toast("Contract Created", "success", 3000);
          this.resetNewDeliveryMethod();
          this.loadContracts();
        })
        .catch(({ response }) => {
          NProgress.done();
          this.$helpers.toast(
            this.$t("integrationsPage.contractNotCreated"),
            "danger",
            3000
          );
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errors = response.data.messages[0];
          }
        });
    },
    setObsolete(contract) {
      contractService
        .setObsolete(contract.id)
        .then(() => {
          this.contractsBySlugName.splice(contract.index, 1);
          this.loadContracts();
          this.$helpers.toast(
            this.$t("integrationsPage.contractDeleted"),
            "success",
            3000
          );
          this.$helpers.closeModal("modalDeleteCourier");
        })
        .catch(() => {
          NProgress.done();
          this.$helpers.toast(
            this.$t("integrationsPage.contractNotdeleted"),
            "danger",
            3000
          );
        });
    },
    setContractToDelete(id, index) {
      this.contractToDelete = {};
      this.contractToDelete = {
        id,
        index,
      };
    },
    loadPriceTables() {
      /* const priceTables = [{"id":2,"name":"teste","created_at":"2023-10-09T19:55:29.427Z","updated_at":"2023-10-09T19:55:29.427Z","platform_id":1,"is_made_by_seller":true,"seller_id":1}];
				this.priceTablesOptions = priceTables.map(
					({id, name}) => ({
						value: id,
						text: `${id} - ${name}`,
					})
				) */

      priceTablesService.getPriceTables().then((response) => {
        const priceTables = response.data.data.elements;
        this.priceTablesOptions = priceTables.map(({ id, name }) => ({
          value: id,
          text: `${id} - ${name}`,
        }));
      });
    },
    loadDeliveryTimes() {
      /* const deliveryTimes = [{"id":3,"name":"dfrgtyhujisdfdgfsa","created_at":"2023-10-09T22:53:40.600Z","updated_at":"2023-10-09T23:04:59.766Z","platform_id":1,"is_made_by_seller":true,"seller_id":1},{"id":2,"name":"teste","created_at":"2023-10-09T19:56:45.336Z","updated_at":"2023-10-09T19:56:45.336Z","platform_id":1,"is_made_by_seller":true,"seller_id":1}]
				this.deliveryTimesOptions = deliveryTimes.map(
					({id, name}) => ({
						value: id,
						text: `${id} - ${name}`,
					})
				) */

      deliveryTimesService.getDeliveryTimes().then((response) => {
        const deliveryTimes = response.data.data.elements;
        this.deliveryTimesOptions = deliveryTimes.map(({ id, name }) => ({
          value: id,
          text: `${id} - ${name}`,
        }));
      });
    },
    loadContracts() {
      contractService.getContractsSellerActive().then((response) => {
        this.contracts = response.data.data;
      });
    },
    checkIfIsConnected(slug_name) {
      let finalResults = {
        isConnected: false,
        currentConnections: [],
      };
      for (let i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].dm_slug === slug_name) {
          finalResults.currentConnections.push(this.contracts[i]);
          finalResults.isConnected = true;
        }
      }
      return finalResults;
    },
    countConnectedContracts(slug_name) {
      let count = 0;
      for (let i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].dm_slug === slug_name) {
          count++;
        }
      }
      return count;
    },
    getContractsBySlugName(slug_name) {
      this.contractsBySlugName = [];
      this.contractsBySlugName = this.contracts.filter(
        (contract) => contract.dm_slug === slug_name
      );
      return this.contractsBySlugName;
    },
    resetNewDeliveryMethod(keysForm) {
      this.newDeliveryMethod = {};
      this.contractToDelete = {};
      this.errors = {};
      this.currentKeys = [];
      if (keysForm) {
        this.currentKeys = this.allkeys[keysForm];
        this.allkeys[keysForm].forEach((keys) => {
          this.newDeliveryMethod[keys.key] = "";
        });
      }
    },
    modalLink(slug_name) {
      const contractCount = this.countConnectedContracts(slug_name);
      if (contractCount > 0) {
        return {
          message: `You already have 
					${contractCount}
					${contractCount === 1 ? "integration" : "integrations"}
					with this carrier.`,
          link: {
            src: "integrationList",
            text: "Click here to view them >",
          },
        };
      }
      return null;
    },
    switchModalForm(event, slug_name) {
      this.getContractsBySlugName(slug_name);
      this.showModalForm = event;
    },
    copyContracToNewDeliveryMethod(contract, keysForm) {
      this.newDeliveryMethod = JSON.parse(
        JSON.stringify(this.newDeliveryMethodModel)
      );
      this.resetNewDeliveryMethod(keysForm);
      this.newDeliveryMethod = { ...contract };
      this.allkeys[keysForm].forEach((keys) => {
        //treatment of different variables in the back VS front;
        if (keys.key == "slug") {
          this.newDeliveryMethod[keys.key] = contract.name || null;
          return;
        }

        /* 
				if(
					contract.delivery_methods_decrypted &&
					contract.delivery_methods_decrypted[0] &&
					contract.delivery_methods_decrypted[0].tracking_api_credentials
				) {
					if(keys.key == 'tracking_senha') {
						this.newDeliveryMethod[keys.key] = contract.delivery_methods_decrypted[0].tracking_api_credentials.Senha || null;
						return;
					}
					if(keys.key == 'tracking_usuario') {
						this.newDeliveryMethod[keys.key] = contract.delivery_methods_decrypted[0].tracking_api_credentials.Usuario || null;
						return;
					}
				}
				*/

        if (
          contract.delivery_methods_decrypted &&
          contract.delivery_methods_decrypted[0] &&
          contract.delivery_methods_decrypted[0].dispatch_api_credentials
        ) {
          if (keys.key == "dispatch_login_hash") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .LoginHash || null;
            return;
          }

          if (keys.key == "dispatch_cartao_postagem") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .CartaoPostagem || null;
            return;
          }

          if (keys.key == "dispatch_authorization") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .Authorization || null;
            return;
          }

          if (keys.key == "dispatch_contract") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .contract || null;
            return;
          }

          if (keys.key == "dispatch_key") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .key || null;
            return;
          }

          if (keys.key == "dispatch_token") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .token || null;
            return;
          }

          if (keys.key == "dispatch_app_key") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .appKey || null;
            return;
          }

          if (keys.key == "dispatch_app_token") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .appToken || null;
            return;
          }

          if (keys.key == "dispatch_login") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .login || null;
            return;
          }

          if (keys.key == "dispatch_username") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .username || null;
            return;
          }

          if (keys.key == "dispatch_password") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .password || null;
            return;
          }

          if (keys.key == "dispatch_line_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .lineCode || null;
            return;
          }

          if (keys.key == "dispatch_remetente_id") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .remetenteId || null;
            return;
          }

          if (keys.key == "dispatch_external_customer_id") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .external_customer_id || null;
            return;
          }

          if (keys.key == "dispatch_external_reference_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .external_reference_code || null;
            return;
          }

          if (keys.key == "dispatch_service_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .service_code || null;
            return;
          }

          if (keys.key == "dispatch_shipper_account_number") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .shipper_account_number || null;
            return;
          }

          if (keys.key == "dispatch_billing_account_number") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .billing_account_number || null;
            return;
          }

          if (keys.key == "dispatch_channel_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .channel_code || null;
            return;
          }

          if (keys.key == "dispatch_logistics_server_token") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .LogisticsToken || null;
            return;
          }

          if (keys.key == "dispatch_user_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .user_code || null;
            return;
          }

          if (keys.key == "dispatch_user_key") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .user_key || null;
            return;
          }

          if (keys.key == "dispatch_skypostal_app_key") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .app_key || null;
            return;
          }

          if (keys.key == "dispatch_copa_id") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .copa_id || null;
            return;
          }

          if (keys.key == "dispatch_box_id") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .box_id || null;
            return;
          }

          if (keys.key == "dispatch_first_mile_service_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .first_mile_service_code || null;
            return;
          }

          if (keys.key == "dispatch_service_code") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .service_code || null;
            return;
          }

          if (keys.key == "dispatch_user_id") {
            this.newDeliveryMethod[keys.key] =
              contract.delivery_methods_decrypted[0].dispatch_api_credentials
                .userId || null;
            return;
          }
        }

        //set current courier keys into newDeliveryMethod when back and front has same key;
        this.newDeliveryMethod[keys.key] = contract[keys.key];
      });

      this.showModalForm = "";
    },

    updateValidFields(validFieldId) {
      this.errors[validFieldId] = "";
      delete this.errors[validFieldId];

      this.checkFormRules();
    },
    invalid(invalidField) {
      if (invalidField && invalidField.message) {
        this.errors = {
          ...this.errors,
          [invalidField.id]: [invalidField.message],
        };
      }

      this.checkFormRules();
    },
    checkFormRules() {
      //count required inputs
      this.allOk = true;

      const requiredKeys = this.currentKeys.reduce((keys, currentKey) => {
        if (currentKey.required) {
          keys.push(currentKey.key);
        }
        return keys;
      }, []);

      this.allOk = requiredKeys.some(
        (key) =>
          !this.newDeliveryMethod[key] ||
          String(this.newDeliveryMethod[key]).trim() === ""
      );
    },
  },
  computed: {
    possibleConctiones() {
      return [
        {
          imagem: "https://cas.correios.com.br/ect/img/logo-ect.svg",
          label: "CORREIOS",
          slug_name: "correios",
          keysForm: "correiosKeys",
          modalTitle: this.$t("integrationsPage.correiosTitle"),
          modalMessage: this.$t("integrationsPage.correiosText"),
          okButton: this.$t("integrationsPage.correiosOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/total.png"),
          label: "Total Express",
          slug_name: "total",
          keysForm: "totalKeys",
          modalTitle: this.$t("integrationsPage.totalTitle"),
          modalMessage: this.$t("integrationsPage.totalText"),
          okButton: this.$t("integrationsPage.totalOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/bringer.jpg"),
          label: "Bringer",
          slug_name: "bringer",
          keysForm: "bringerKeys",
          modalTitle: this.$t("integrationsPage.bringerTitle"),
          modalMessage: this.$t("integrationsPage.bringerText"),
          okButton: this.$t("integrationsPage.bringerOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/dhl-logo.svg"),
          label: "DHL",
          slug_name: "dhl",
          keysForm: "dhlKeys",
          modalTitle: this.$t("integrationsPage.dhlTitle"),
          modalMessage: this.$t("integrationsPage.dhlText"),
          okButton: this.$t("integrationsPage.dhlOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/herco.jpeg"),
          label: "Herco",
          slug_name: "herco",
          keysForm: "genericKeys",
          modalTitle: this.$t("integrationsPage.hercoTitle"),
          modalMessage: this.$t("integrationsPage.hercoText"),
          okButton: this.$t("integrationsPage.hercoOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/kparcel.png"),
          label: "K-PARCEL",
          slug_name: "kparcel",
          keysForm: "kparcelKeys",
          modalTitle: this.$t("integrationsPage.kparcelTitle"),
          modalMessage: this.$t("integrationsPage.kparcelText"),
          okButton: this.$t("integrationsPage.kparcelOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/sinerlog.png"),
          label: "SINERLOG",
          slug_name: "sinerlog",
          keysForm: "genericKeys",
          modalTitle: this.$t("integrationsPage.sinerlogTitle"),
          modalMessage: this.$t("integrationsPage.sinerlogText"),
          okButton: this.$t("integrationsPage.sinerlogOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/skypostal.png"),
          label: "Sky Postal",
          slug_name: "skypostal",
          keysForm: "skypostalKeys",
          modalTitle: this.$t("integrationsPage.skypostalTitle"),
          modalMessage: this.$t("integrationsPage.skypostalText"),
          okButton: this.$t("integrationsPage.skypostalOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/bil.webp"),
          label: "Bil",
          slug_name: "bil",
          keysForm: "bilKeys",
          modalTitle: this.$t("integrationsPage.bilTitle"),
          modalMessage: this.$t("integrationsPage.bilText"),
          okButton: this.$t("integrationsPage.bilOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/360lion-logo.png"),
          label: "360Lion",
          slug_name: "lion",
          keysForm: "lionKeys",
          modalTitle: this.$t("integrationsPage.lionTitle"),
          modalMessage: this.$t("integrationsPage.lionText"),
          okButton: this.$t("integrationsPage.lionOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/mailamericas-logo.png"),
          label: "MAIL AMERICAS",
          slug_name: "mail_americas",
          keysForm: "mailAmericasKeys",
          modalTitle: this.$t("integrationsPage.mailAmericasTitle"),
          modalMessage: this.$t("integrationsPage.mailAmericasText"),
          okButton: this.$t("integrationsPage.mailAmericasOkButton"),
        },
        {
          imagem: require("@/assets/img/couriers/br1express-logo.png"),
          label: "BR1 EXPRESS",
          slug_name: "br1",
          keysForm: "br1Keys",
          modalTitle: this.$t("integrationsPage.br1ExpressTitle"),
          modalMessage: this.$t("integrationsPage.br1ExpressText"),
          okButton: this.$t("integrationsPage.br1ExpressOkButton"),
        },
      ];
    },
    allkeys() {
      return {
        totalKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_remetente_id",
            key: "dispatch_remetente_id",
            name: this.$t("integrationsPage.remetenteId"),
          },
          {
            required: true,
            id: "dispatch_username",
            key: "dispatch_username",
            name: this.$t("integrationsPage.usuario"),
          },
          {
            required: true,
            id: "dispatch_password",
            key: "dispatch_password",
            name: this.$t("integrationsPage.senha"),
          },
        ],
        bringerKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_username",
            key: "dispatch_username",
            name: this.$t("integrationsPage.usuario"),
          },
          {
            required: true,
            id: "dispatch_password",
            key: "dispatch_password",
            name: this.$t("integrationsPage.senha"),
          },
          {
            required: true,
            id: "dispatch_external_customer_id",
            key: "dispatch_external_customer_id",
            name: this.$t("integrationsPage.externalCustomerId"),
          },
          {
            required: true,
            id: "dispatch_external_reference_code",
            key: "dispatch_external_reference_code",
            name: this.$t("integrationsPage.externalReferenceCode"),
          },
          {
            required: true,
            id: "dispatch_service_code",
            key: "dispatch_service_code",
            name: this.$t("integrationsPage.dispatchServiceCode"),
          },
        ],
        dhlKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_key",
            key: "dispatch_key",
            name: this.$t("integrationsPage.key"),
          },
          {
            required: true,
            id: "dispatch_password",
            key: "dispatch_password",
            name: this.$t("integrationsPage.senha"),
          },
          {
            required: true,
            id: "dispatch_shipper_account_number",
            key: "dispatch_shipper_account_number",
            name: this.$t("integrationsPage.shipperAccountNumber"),
          },
          {
            required: true,
            id: "dispatch_billing_account_number",
            key: "dispatch_billing_account_number",
            name: this.$t("integrationsPage.billingAccountNumber"),
          },
        ],
        kparcelKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_username",
            key: "dispatch_username",
            name: this.$t("integrationsPage.usuario"),
          },
          {
            required: true,
            id: "dispatch_password",
            key: "dispatch_password",
            name: this.$t("integrationsPage.senha"),
          },
          {
            required: true,
            id: "dispatch_channel_code",
            key: "dispatch_channel_code",
            name: this.$t("integrationsPage.channelCode"),
          },
        ],
        skypostalKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_user_code",
            key: "dispatch_user_code",
            name: this.$t("integrationsPage.userCode"),
          },
          {
            required: true,
            id: "dispatch_user_key",
            key: "dispatch_user_key",
            name: this.$t("integrationsPage.userKey"),
          },
          {
            required: true,
            id: "dispatch_skypostal_app_key",
            key: "dispatch_skypostal_app_key",
            name: this.$t("integrationsPage.key"),
          },
          {
            required: true,
            id: "dispatch_copa_id",
            key: "dispatch_copa_id",
            name: this.$t("integrationsPage.copaId"),
          },
          {
            required: true,
            id: "dispatch_box_id",
            key: "dispatch_box_id",
            name: this.$t("integrationsPage.boxId"),
          },
          {
            required: true,
            id: "dispatch_first_mile_service_code",
            key: "dispatch_first_mile_service_code",
            name: this.$t("integrationsPage.firstMileServiceCode"),
          },
          {
            required: true,
            id: "dispatch_service_code",
            key: "dispatch_service_code",
            name: this.$t("integrationsPage.serviceCode"),
          },
        ],
        bilKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_user_id",
            key: "dispatch_user_id",
            name: this.$t("integrationsPage.userId"),
          },
          {
            required: true,
            id: "dispatch_password",
            key: "dispatch_password",
            name: this.$t("integrationsPage.senha"),
          },
          {
            required: true,
            id: "dispatch_authorization",
            key: "dispatch_authorization",
            name: this.$t("integrationsPage.authorization"),
          },
        ],
        genericKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_token",
            key: "dispatch_token",
            name: this.$t("integrationsPage.token"),
          },
        ],
        mailAmericasKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_token",
            key: "dispatch_token",
            name: this.$t("integrationsPage.token"),
          },
          {
            required: true,
            id: "dispatch_service_code",
            key: "dispatch_service_code",
            name: this.$t("integrationsPage.serviceCode"),
          },
        ],
        correiosKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_login_hash",
            key: "dispatch_login_hash",
            name: this.$t("integrationsPage.loginHash"),
          },
          {
            required: true,
            id: "dispatch_cartao_postagem",
            key: "dispatch_cartao_postagem",
            name: this.$t("integrationsPage.cartaoPostagem"),
          },
          {
            required: true,
            id: "dispatch_authorization",
            key: "dispatch_authorization",
            name: this.$t("integrationsPage.authorization"),
          },
          {
            required: true,
            id: "dispatch_contract",
            key: "dispatch_contract",
            name: this.$t("integrationsPage.contract"),
          },
        ],
        lionKeys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_key",
            key: "dispatch_key",
            name: this.$t("integrationsPage.key"),
          },
          {
            required: true,
            id: "dispatch_token",
            key: "dispatch_token",
            name: this.$t("integrationsPage.token"),
          },
          {
            required: true,
            id: "dispatch_service_code",
            key: "dispatch_service_code",
            name: this.$t("integrationsPage.serviceCode"),
          },
        ],
        br1Keys: [
          {
            required: true,
            id: "slug",
            key: "slug",
            name: this.$t("integrationsPage.name"),
          },
          {
            required: true,
            id: "price_table_id",
            key: "price_table_id",
            name: this.$t("integrationsPage.price_table"),
            type: "select",
            options: this.priceTablesOptions,
          },
          {
            id: "delivery_time_id",
            key: "delivery_time_id",
            name: this.$t("integrationsPage.delivery_time"),
            type: "select",
            options: this.deliveryTimesOptions,
          },
          {
            required: true,
            id: "dispatch_app_key",
            key: "dispatch_app_key",
            name: this.$t("integrationsPage.key"),
          },
          {
            required: true,
            id: "dispatch_app_token",
            key: "dispatch_app_token",
            name: this.$t("integrationsPage.token"),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.button-bottom {
  bottom: 17px;
  position: absolute;
}
</style>
