<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <!-- <NbPageTitle :title="$t('integrationsPage.title')" :subTitle="$t('integrationsPage.firstText')" /> -->
    <NbPageTitle
      :title="
        currentTable == 'Prices Table'
          ? $t('priceTablesPage.titlePrice')
          : $t('priceTablesPage.titleDelivery')
      "
    >
      <NbButton
        variant="secondary"
        size="sm"
        @click="
          currentTable === 'Prices Table'
            ? initializePriceTables()
            : initializeDeliveryTime()
        "
        data-toggle="modal"
        data-target="#addPriceTables"
      >
        {{
          currentTable === "Prices Table"
            ? $t("priceTablesPage.importPricesTable")
            : $t("priceTablesPage.importDeliveryTime")
        }}
      </NbButton>
    </NbPageTitle>
    <NbTabs class="mb-3" :tabs="tabsOptions" @switchTab="switchTab($event)" />

    <!-- esquerda -->
    <NbTablev2
      ref="price_tables"
      :class="currentTable == 'Prices Table' ? '' : 'display-none'"
      tableOf="priceTablesPage"
      :namespace="'price_tables'"
      :filterOptions="filterOptions"
      :allFields="allFields"
      :fields="fields"
      @reloadFields="fields = $event"
      @optionTook="optionTook($event)"
      @total="totalPriceTables = $event"
    >
      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          icon="fas fa-file-pdf"
          title="Download in PDF"
          @click="downloadPdf(data.item.id, currentTable)"
        >
          Download in PDF
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-file-csv"
          title="Download in CSV"
          @click="downloadCsv(data.item.id, data.item.name, currentTable)"
        >
          Download in CSV
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-trash"
          :title="`Delete this Price Table ${data.item.name}`"
          data-toggle="modal"
          data-target="#modalDeletePriceTable"
          v-on:click="
            priceTableToDelete = data.item;
            setPriceTableName(data.item.name);
            ifDeletePriceTable = true;
          "
        >
          Delete
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-edit"
          data-toggle="modal"
          data-target="#editPriceTables"
          @click="copyPriceTables(data.item, 'price_table')"
          :title="`Edit Contract ${data.item.name}`"
        >
          Edit
        </NbButton>
      </template>
    </NbTablev2>

    <!-- direita -->
    <NbTablev2
      ref="delivery_times"
      :class="currentTable == 'Delivery Times' ? '' : 'display-none'"
      tableOf="deliveryTimesPage"
      :namespace="'delivery_times'"
      :filterOptions="filterOptions"
      :allFields="allFields"
      :fields="fields"
      @reloadFields="fields = $event"
      @optionTook="optionTook($event)"
      @total="totalDeliveryTimes = $event"
    >
      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          icon="fas fa-file-pdf"
          title="Download in PDF"
          @click="downloadPdf(data.item.id, currentTable)"
        >
          Download in PDF
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-file-csv"
          title="Download in CSV"
          @click="downloadCsv(data.item.id, data.item.name, currentTable)"
        >
          Download in CSV
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-trash"
          :title="`Delete this Delivey Time ${data.item.name}`"
          data-toggle="modal"
          data-target="#modalDeletePriceTable"
          v-on:click="
            priceTableToDelete = data.item;
            setPriceTableName(data.item.name);
            ifDeletePriceTable = false;
          "
        >
          Delete
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="fas fa-edit"
          data-toggle="modal"
          data-target="#editPriceTables"
          @click="copyPriceTables(data.item, 'price_table')"
          :title="`Edit Contract ${data.item.name}`"
        >
          Edit
        </NbButton>
      </template>
    </NbTablev2>

    <!-- modals -->
    <NbModal
      id="addPriceTables"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.import") }}
          {{
            currentTable === "Prices Table" ? "Prices Table" : "Delivery Time"
          }}
        </div>
      </template>

      <template v-slot:body>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="name">
                {{
                  currentTable === "Prices Table"
                    ? "Price Table"
                    : "Delivery Time"
                }}
                {{ $t("priceTablesPage.name") }} *</label
              >
              <input
                v-model="newItem.name"
                type="text"
                :class="{ 'is-invalid': errors['name'] }"
                class="form-control"
                id="name"
                aria-describedby="nameHelp"
                placeholder="Name .."
              />
              <div
                class="invalid-feedback"
                v-for="(error, index) in errors['name']"
                :key="index"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center">
              <h6 class="card-title text-center">
                <i class="fas fa-cloud-upload-alt fa-3x"></i>
              </h6>
              <p class="my-0">
                {{ $t("priceTablesPage.filesUpload") }}
                {{
                  currentTable === "Prices Table"
                    ? "prices table"
                    : "delivery time"
                }}.
              </p>
              <p>{{ $t("priceTablesPage.selectCsv") }}.</p>
              <label
                class="btn btn-success"
                :class="
                  (newItem.name.length > 0 ? null : 'btn-disabled') ||
                  ifPriceModal ||
                  (newItem.name.length > 0 ? null : 'btn-disabled')
                "
              >
                <span v-if="uploading">
                  <span class="spinner-border spinner-border-sm"></span>
                  {{ $t("uploading") }}
                </span>

                <span v-if="!uploading">
                  {{ $t("upload") }}
                  <input
                    type="file"
                    class="d-none"
                    @change="processFile"
                    :disabled="newItem.name.length > 0 ? false : true"
                    accept=".csv"
                  />
                </span>
              </label>
              <p class="text-center my-0" v-if="file">
                {{ $t("priceTablesPage.fileUploaded") }}: {{ chosenFile.name }}
              </p>
              <p class="text-center mt-3">
                <a
                  :href="
                    currentTable === 'Prices Table'
                      ? '/files/price-tables.csv'
                      : '/files/delivery-time.csv'
                  "
                  >{{ $t("priceTablesPage.downloadHere") }}</a
                >
                {{ $t("priceTablesPage.ablankTable") }}.
              </p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            title="Save Column List"
            :disabled="!validateForm()"
            @click="addNew(currentTable)"
          >
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ isLoading ? "Loading" : "Save" }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="modalDeletePriceTable"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.suretodelete") }}
          {{
            currentTable === "Prices Table" ? "Prices Table" : "Delivery Time"
          }}?
        </div>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            title="Save Column List"
            @click="deleteItem(priceTableToDelete, currentTable)"
          >
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="editPriceTables"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.import") }}
          {{
            currentTable === "Prices Table" ? "Prices Table" : "Delivery Time"
          }}
        </div>
      </template>

      <template v-slot:body>
        <div class="row">
          <div class="col">
            <div class="text-center">
              <h6 class="card-title text-center">
                <i class="fas fa-cloud-upload-alt fa-3x"></i>
              </h6>
              <p class="my-0">
                {{ $t("priceTablesPage.filesUpload") }}
                {{
                  currentTable === "Prices Table"
                    ? "prices table"
                    : "delivery time"
                }}.
              </p>
              <p>{{ $t("priceTablesPage.selectCsv") }}.</p>
              <label
                class="btn btn-success"
                :class="
                  (newItem.name.length > 0 ? null : 'btn-disabled') ||
                  ifPriceModal ||
                  (newItem.name.length > 0 ? null : 'btn-disabled')
                "
              >
                <span v-if="uploading">
                  <span class="spinner-border spinner-border-sm"></span>
                  {{ $t("uploading") }}
                </span>

                <span v-if="!uploading">
                  {{ $t("upload") }}
                  <input
                    type="file"
                    class="d-none"
                    @change="processFile"
                    :disabled="newItem.name.length > 0 ? false : true"
                    accept=".csv"
                  />
                </span>
              </label>
              <p class="text-center my-0" v-if="file">
                {{ $t("priceTablesPage.fileUploaded") }}: {{ chosenFile.name }}
              </p>
              <p class="text-center mt-3">
                <a
                  :href="
                    currentTable === 'Prices Table'
                      ? '/files/price-tables.csv'
                      : '/files/delivery-time.csv'
                  "
                  >{{ $t("priceTablesPage.downloadHere") }}</a
                >
                {{ $t("priceTablesPage.ablankTable") }}.
              </p>
            </div>
          </div>
        </div>
        <div class="row" v-if="currentTable === 'Prices Table' && isUpdate">
          <div class="col">
            <!-- <div
              class="col mt-2 mr-4"
              v-if="admins !== null && admins.length > 0"
            >
              <h5 class="mr-3 ml-1">
                {{ $t("priceTablesPage.needsSupervisorApproval") }}.
              </h5>
              <p>{{ $t("priceTablesPage.chooseToSendRequest") }}:</p>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="selected_admins"
                  :aria-describedby="ariaDescribedby"
                  :options="admins"
                />
              </b-form-group>
            </div> -->
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            title="Save Column List"
            :disabled="!validateForm()"
            @click="addNew(currentTable)"
          >
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ isLoading ? "Loading" : "Save" }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NProgress from "nprogress";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import PendingActionService from "@/services/PendingActionService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";

//services
import PriceTablesService from "@/services/PriceTablesService";
import DeliveryTimesService from "@/services/DeliveryTimesService";
import DownloadsService from "@/services/DownloadsService";

const priceTablesService = new PriceTablesService();
const deliveryTimesService = new DeliveryTimesService();
const downloadsService = new DownloadsService();
const pendingActionService = new PendingActionService();

export default {
  name: "IntegrationsList",
  components: {
    LoadingPage,
    NbPageTitle,
    NbTabs,
    NbTablev2,
    NbButton,
    NbModal,
  },
  data: () => {
    return {
      errors: {},
      newItem: {
        name: "",
      },
      ifPriceModal: true,
      priceTableName: "",
      deliveryTimesName: "",
      uploading: false,
      file: null,
      chosenFile: null,
      admins: null,
      selected_admins: [],
      priceTables: [],
      deliveryTimes: [],
      fields: [],
      isUpdate: false,
      filterOptions: [{ key: "name", label: "Name" }],
      currentTable: "Prices Table",
      isLoading: false,

      totalPriceTables: 0,

      totalDeliveryTimes: 0,

      loaded: true,
    };
  },
  created() {
    pendingActionService.fetchAdminUsers().then((response) => {
      this.admins = response.data.data.map((admin) => ({
        text: admin.name,
        value: admin.id,
      }));
    });
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
  },
  methods: {
    loadPriceTables() {
      priceTablesService.getPriceTables().then((response) => {
        this.priceTables = response.data.data.elements;
      });
    },
    loadDeliveryTimes() {
      deliveryTimesService.getDeliveryTimes().then((response) => {
        this.deliveryTimes = response.data.data.elements;
      });
    },
    initializePriceTables() {
      this.errors = {};
      this.newItem.name = "";
      this.isUpdate = false;
      this.ifPriceModal = true;
      this.chosenFile = null;
      this.file = null;
    },
    initializeDeliveryTime() {
      this.errors = {};
      this.newItem.name = "";
      this.isUpdate = false;
      this.ifPriceModal = false;
      this.chosenFile = null;
      this.file = null;
    },
    processFile(event) {
      this.uploading = true;
      this.chosenFile = event.target.files[0];

      if (!this.validateForm(true)) {
        return;
      }

      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);
      setTimeout(() => {
        this.uploading = false;
      }, 1100);
    },
    checkValidation(ifPriceModal) {
      if (ifPriceModal === "Prices Table") {
        if (this.validateForm() && this.selected_admins.length < 1) {
          return true;
        }
        return false;
      } else {
        return !this.validateForm();
      }
    },
    validateForm(showMessage) {
      if (this.isUpdate) {
        return true;
      }
      if (this.chosenFile != null) {
        if (this.chosenFile.type !== "text/csv") {
          if (showMessage) {
            this.$helpers.toast("File is not .csv", "danger", 5000);
          }
          this.uploading = false;
          return false;
        }
        if (this.newItem.name === "") {
          this.uploading = false;
          return false;
        }
        return true;
      }
      return false;
    },
    addNew(ifPriceModal) {
      if (ifPriceModal === "Prices Table") {
        this.createPriceTables();
      } else {
        this.createDeliveryTimes();
      }
    },
    initiateFileUpload(dataQuery) {
      this.isLoading = true;
      downloadsService.loadProcessing(dataQuery);
      setTimeout(() => {
        this.$helpers.closeModal("editPriceTables");
        this.$helpers.closeModal("addPriceTables");
        this.$router.push("/archives");
      }, 2000);
    },
    createPriceTables() {
      this.file.append("name", this.newItem.name);
      this.file.append("item_id", this.newItem.id);
      this.file.append("approver_ids", JSON.stringify(this.selected_admins));
      this.file.append("data_type", "price_table");
      this.initiateFileUpload(this.file);
    },
    createDeliveryTimes() {
      this.file.append("name", this.newItem.name);
      this.file.append("item_id", this.newItem.id);
      this.file.append("data_type", "delivery_time");
      this.initiateFileUpload(this.file);
    },
    downloadPdf(id, ifPrice) {
      let service =
        ifPrice === "Prices Table" ? priceTablesService : deliveryTimesService;
      let filename = ifPrice
        ? `price_table_${id}.pdf`
        : `delivery_time_${id}.pdf`;
      service.downloadPdf(id).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = filename;
          download_link.click();
        }
      });
    },
    deleteItem(item, ifPriceTable) {
      let target =
        ifPriceTable === "Prices Table" ? "price_tables" : "delivery_times";
      let service =
        ifPriceTable === "Prices Table"
          ? priceTablesService
          : deliveryTimesService;
      service
        .delete(item.id)
        .then(() => {
          this.$refs[target].getData(); //loadItems()  to reload the table
          this.$helpers.closeModal("modalDeletePriceTable");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages"
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    downloadCsv(id, name, ifPrice) {
      let service =
        ifPrice === "Prices Table" ? priceTablesService : deliveryTimesService;
      let filename = ifPrice
        ? `price_table_${name}.csv`
        : `delivery_time_${name}.csv`;
      service.downloadCsv(id).then((response) => {
        let csv =
          "data:text/csv; charset=utf-8," + encodeURIComponent(response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    setPriceTableName(name) {
      this.priceTableName = name;
    },
    setDeliveryTimeName(name) {
      this.deliveryTimesName = name;
    },
    copyPriceTables(table, target) {
      let service =
        target === "delivery_time" ? deliveryTimesService : priceTablesService;
      if (table) {
        this.ifPriceModal = target === "delivery_time" ? false : true;
        this.newItem = JSON.parse(JSON.stringify(table));
        service.downloadCsv(table.id).then((response) => {
          const csv = new Blob([response.data], { type: "text/csv" });
          this.file = new FormData();
          this.chosenFile = { name: `${target}_${table.name}.csv` };
          this.file.append("file", csv, `${target}_${table.name}.csv`);
          this.isUpdate = true;
        });
      }
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    optionTook(event) {
      //TODO: check se tem # e remove ele para exec??
      this[event]();
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "Prices Table",
          label: "Prices Table",
          found: this.totalPriceTables,
          current: this.currentTable,
        },
        {
          id: "Delivery Times",
          label: "Delivery Times",
          found: this.totalDeliveryTimes,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("priceTablesPage.fields.name"),
          sortable: false,
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("priceTablesPage.fields.actions"),
        },
      ];
    },
  },
};
</script>
