import HttpService from "./HttpService";

export default class DeliveryTimesService {
  constructor() {
    this.httpService = HttpService;
  }

  getDeliveryTimes() {
    return this.httpService.get("/v1/delivery_times");
  }

  delete(id) {
    return this.httpService.delete(`/v1/delivery_times/${id}`);
  }

  downloadPdf(id) {
    return this.httpService.get(`/v1/delivery_times/${id}/download_pdf`);
  }

  downloadCsv(id) {
    return this.httpService.get(`/v1/delivery_times/${id}/download_csv`);
  }
}
